import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { Avatar, Box, List, ListItemText, Stack } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ListItemStyle, PermissionChecker, Icons, LightTooltip } from 'components';
import { getStringWithoutSpaces } from 'helpers';

import { CollapsedMenu } from './CollapsedMenu';

const NavItem = ({ menuItem, isSidebarCollapsed }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [t] = useTranslation('common');
  const { title, path, icon, permission, submenuItems } = menuItem;
  const isMainScreen = path === '/';
  const isActiveRoot = path ? !!matchPath({ path, end: isMainScreen }, pathname) : false;
  const sidebarItemDataCy = `${getStringWithoutSpaces(title)}SidebarItem`;
  const [anchorEl, setAnchorEl] = useState(null);

  const listItemIconStyle = {
    width: 40,
    height: 40,
    padding: '0.38rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginRight: '0.9rem',
    color: isActiveRoot ? theme.palette.primary.main : theme.palette.gray.main
  };
  const activeRootStyle = {
    color: 'primary.main',
    '&:before': { display: 'block' }
  };

  const listItemStyle = {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.gray.lightest
    }
  };

  const ListItemTextStyle = styled(ListItemText)(() => ({
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    color: isActiveRoot ? theme.palette.primary.main : theme.palette.gray.main,
    textWrap: 'nowrap'
  }));

  return (
    <>
      {submenuItems ? (
        <ListItemStyle
          disableRipple
          component='div'
          onClick={e => {
            anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
          }}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
          dataCy={sidebarItemDataCy}>
          <Stack
            sx={[
              {
                backgroundColor: (isActiveRoot || anchorEl) && theme.palette.gray.lightest
              },
              listItemStyle
            ]}>
            <CollapsedMenu
              title={title}
              icon={icon}
              isSidebarCollapsed={isSidebarCollapsed}
              listItemIconStyle={listItemIconStyle}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <ListItemTextStyle disableTypography primary={title} />
              <div style={{ marginRight: '0.8rem' }}>
                <Icons
                  iconName='arrowRightIcon'
                  fill={isActiveRoot ? theme.palette.primary.main : theme.palette.gray.main}
                />
              </div>
            </div>
          </Stack>
        </ListItemStyle>
      ) : (
        <PermissionChecker permissions={permission}>
          <ListItemStyle
            disableRipple
            dataCy={sidebarItemDataCy}
            component={RouterLink}
            to={path}
            sx={{
              ...(isActiveRoot && activeRootStyle)
            }}>
            <LightTooltip title={!isSidebarCollapsed && t(title)} placement='right'>
              <Stack
                sx={[
                  { backgroundColor: isActiveRoot && theme.palette.gray.lightest },
                  listItemStyle
                ]}>
                <Avatar sx={listItemIconStyle}>{icon}</Avatar>
                <ListItemTextStyle disableTypography primary={t(title)} />
              </Stack>
            </LightTooltip>
          </ListItemStyle>
        </PermissionChecker>
      )}
    </>
  );
};

NavItem.propTypes = {
  isSidebarCollapsed: PropTypes.bool,
  menuItem: PropTypes.object
};

export const NavSection = ({ navConfig, isSidebarCollapsed = true, ...other }) => {
  return (
    <Box {...other}>
      {navConfig.map(list => {
        return (
          <List key={list.items[0].title} disablePadding>
            {list.items.map(item => (
              <NavItem key={item.title} menuItem={item} isSidebarCollapsed={isSidebarCollapsed} />
            ))}
          </List>
        );
      })}
    </Box>
  );
};

NavSection.propTypes = {
  navConfig: PropTypes.array,
  isSidebarCollapsed: PropTypes.bool
};
