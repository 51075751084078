import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { LabelChips, LabelMenu } from 'components';

import { useStyles } from './LabelSelection.css.js';

export const LabelSelect = ({ onChange, selected, ...props }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);
  const [selectedLabels, setSelectedLabels] = useState(selected);

  const handleDelete = useCallback(
    id => handleSelection(selectedLabels.filter(l => l.id !== id)),
    [selectedLabels]
  );

  const handleSelection = useCallback(newSelection => {
    setSelectedLabels(newSelection);
    onChange(newSelection);
  });

  useEffect(() => {
    setSelectedLabels(selected);
  }, [selected]);

  return (
    <Select
      {...props}
      multiple
      displayEmpty
      value={selectedLabels}
      renderValue={selected =>
        selected?.length ? (
          <LabelChips labels={selected} onDelete={handleDelete} />
        ) : (
          <Typography className={classes.chooseLabel}>{t('chooseLabel')}</Typography>
        )
      }
      MenuProps={{
        PaperProps: { sx: { maxHeight: 310, overflowY: 'hidden' } }
      }}
      className={classes.labelSelect}>
      <LabelMenu
        selectedLabels={selectedLabels}
        setSelectedLabels={handleSelection}
        showBackButtonItem={false}
      />
    </Select>
  );
};

LabelSelect.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.array
};
