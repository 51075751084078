import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Card, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons, Scrollbar } from 'components';
import {
  COMMENT_PREFIX,
  changeCommentsHighlighting,
  getElementsWithoutCommentClasses,
  MESSAGE_TYPES,
  removeCommentFormatOnParagraph,
  changeActiveCommentsHighlighting
} from 'helpers';
import { WebSocketConnectionContext } from 'providers';

import { CommentInfo } from './CommentInfo';
import { CommentInputCard } from './CommentInputCard';

export const CommentsTab = ({
  comments,
  getComments,
  commentSelection,
  setCommentSelection,
  selectedCommentId,
  setSelectedCommentId,
  handleOnCommentCardClick,
  selectionDelta,
  isCommentingActive,
  commentInputField,
  commentId,
  setCommentId,
  quillEditor,
  queryCommentId,
  setSelectedCommentTag,
  paragraphLevelCommentIdsRef,
  selectedCommentTag
}) => {
  const theme = useTheme();
  const [queryParentCommentId, setQueryParentCommentId] = useState();
  const [t] = useTranslation('common');
  const { connection, isReconnecting } = useContext(WebSocketConnectionContext);
  const scrollRef = useRef([]);
  const sortedCommentsRef = useRef([]);

  const addingCommentInProgress =
    commentSelection?.length !== -1 && isCommentingActive && !isReconnecting;
  const [isReply, setIsReply] = useState(false);

  const handleCommentId = () => {
    let foundCommentId = false;
    for (const comment of comments) {
      if (comment.id === queryCommentId) {
        setSelectedCommentId(queryCommentId);
        foundCommentId = true;
      } else if (comment.replies.length > 0) {
        for (const reply of comment.replies) {
          if (reply.id === queryCommentId) {
            setSelectedCommentId(reply.parentCommentId);
            setQueryParentCommentId(reply.parentCommentId);
            foundCommentId = true;
            break;
          }
        }
      }
      setIsReply(true);
      if (foundCommentId) {
        break;
      }
    }
  };

  useMemo(() => {
    connection?.on(MESSAGE_TYPES.RECEIVE_COMMENTS_CHANGED, () => {
      getComments(null);
    });
  }, []);

  useMemo(() => {
    if (selectionDelta.current.ops?.some(op => op.attributes?.comment)) {
      sortedCommentsRef.current = quillEditor
        .getContents()
        .ops.filter(op => op?.attributes?.comment)
        .flatMap(op => op.attributes.comment);
    }
  }, [selectionDelta.current]);

  useEffect(() => {
    queryCommentId && handleCommentId();
  }, [comments, queryCommentId]);

  const isScrollNeeded =
    queryCommentId === selectedCommentId || queryParentCommentId === selectedCommentId;

  useEffect(() => {
    if (selectedCommentId && isScrollNeeded) {
      scrollRef?.current[queryCommentId]?.scrollIntoView();
    }
  }, [commentId, queryCommentId]);

  useEffect(() => {
    if (addingCommentInProgress && commentId) {
      changeActiveCommentsHighlighting(false, commentId);
      scrollRef?.current[commentId]?.scrollIntoView();
    }
  }, [addingCommentInProgress, commentId]);

  const noCommentAdded = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '50px'
        }}>
        <Icons iconName='noCommentsAdded' />
      </div>
    );
  };

  const handleCardClick = id => {
    changeCommentsHighlighting(false, null, commentId);
    if (addingCommentInProgress) {
      removeCommentFormatOnParagraph(quillEditor, commentSelection);
      const elements = document.getElementsByClassName(`${COMMENT_PREFIX}${commentId}`);
      getElementsWithoutCommentClasses(elements, commentId);
    }
    setCommentSelection({ index: -1, length: -1 });
    handleOnCommentCardClick(id);
    setIsReply(true);
    setSelectedCommentId(id);
  };

  const clearCommentSelectionAndId = () => {
    setCommentSelection({ index: -1, length: -1 });
    setSelectedCommentId('');
    setCommentId('');
  };

  const sortComments = () =>
    (addingCommentInProgress && !comments.some(c => c.id === commentId)
      ? comments.concat({ id: commentId })
      : comments
    ).toSorted(
      (a, b) => sortedCommentsRef.current.indexOf(a.id) - sortedCommentsRef.current.indexOf(b.id)
    );

  return (
    <Scrollbar sx={{ height: '100%' }}>
      <ClickAwayListener
        onClickAway={() => {
          commentId && changeCommentsHighlighting(false, null, commentId);
          setSelectedCommentId('');
        }}>
        <div className={{ margin: '10px' }}>
          {!comments.length && !addingCommentInProgress && noCommentAdded()}
          {sortComments().map(comment => {
            const hiddenReplies = comment?.replies?.length - 1;
            return addingCommentInProgress && comment.id === commentId ? (
              <CommentInputCard
                key={comment.id}
                commentInputField={commentInputField}
                commentId={commentId}
                quillEditor={quillEditor}
                commentSelection={commentSelection}
                selectionDelta={selectionDelta}
                setSelectedCommentId={setSelectedCommentId}
                queryCommentId={queryCommentId}
                selectedCommentTag={selectedCommentTag}
                clearCommentSelectionAndId={clearCommentSelectionAndId}
                scrollRef={scrollRef}
              />
            ) : (
              <Card
                key={comment.id}
                onClick={() => handleCardClick(comment.id)}
                sx={{
                  zIndex: comment.id === selectedCommentId && 9999,
                  bgcolor:
                    selectedCommentId === comment.id
                      ? theme.palette.gray.white
                      : theme.palette.gray.lighter,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: '15px',
                  mt: '15px',
                  mb: '3px',
                  borderRadius: '5px',
                  boxShadow: `0px 0px 10px 0px ${theme.palette.gray.light}`,
                  cursor: 'pointer',
                  position: 'relative',
                  overflow: 'visible',
                  pointerEvents: 'auto',
                  width: '97%',
                  left: '5px'
                }}>
                <CommentInfo
                  comment={comment}
                  quillEditor={quillEditor}
                  scrollRef={scrollRef}
                  selectedCommentId={selectedCommentId}
                  setSelectedCommentTag={setSelectedCommentTag}
                  paragraphLevelCommentIdsRef={paragraphLevelCommentIdsRef}
                />
                {selectedCommentId !== comment.id && comment?.replies?.length > 1 && (
                  <Typography
                    sx={{
                      fontSize: theme.typography.pxToRem(12),
                      color: theme.palette.primary.main,
                      fontFamily: theme.typography.fontFamilyPrimaryRegular
                    }}>
                    {t('reply', { count: hiddenReplies })}
                  </Typography>
                )}
                {selectedCommentId === comment.id && (
                  <CommentInputCard
                    commentInputField={commentInputField}
                    commentId={comment.id}
                    quillEditor={quillEditor}
                    commentSelection={commentSelection}
                    selectionDelta={selectionDelta}
                    isReply={isReply}
                    setSelectedCommentId={setSelectedCommentId}
                    queryCommentId={queryCommentId}
                    selectedCommentTag={selectedCommentTag}
                    clearCommentSelectionAndId={clearCommentSelectionAndId}
                  />
                )}
              </Card>
            );
          })}
        </div>
      </ClickAwayListener>
    </Scrollbar>
  );
};

CommentsTab.propTypes = {
  comments: PropTypes.array,
  getComments: PropTypes.func,
  commentSelection: PropTypes.object,
  setCommentSelection: PropTypes.func,
  isCommentingActive: PropTypes.bool,
  handleOnCommentCardClick: PropTypes.func,
  selectionDelta: PropTypes.object,
  quillEditor: PropTypes.object,
  commentId: PropTypes.string,
  setCommentId: PropTypes.func,
  commentInputField: PropTypes.object,
  queryCommentId: PropTypes.string,
  selectedCommentId: PropTypes.string,
  setSelectedCommentId: PropTypes.func,
  setSelectedCommentTag: PropTypes.func,
  paragraphLevelCommentIdsRef: PropTypes.object,
  selectedCommentTag: PropTypes.number
};
