import React from 'react';

import { Avatar, useTheme } from '@mui/material';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

export const MyAvatar = ({ displayName, opacity, hover, sx, ...other }) => {
  const theme = useTheme();
  const colorIndex = [...displayName].reduce((acc, char) => acc + char.charCodeAt(0), 0) % 10;

  return (
    <Avatar
      sx={{
        fontFamily: theme.typography.fontFamilyPrimaryRegular,
        color: theme.palette.gray.white,
        backgroundColor: opacity
          ? theme.palette.lightAvatarColors[colorIndex]
          : theme.palette.avatarColors[colorIndex],
        '&:hover': { backgroundColor: hover && theme.palette.avatarColors[colorIndex] },
        ...sx
      }}
      {...other}>
      {capitalize(displayName?.charAt(0))}
    </Avatar>
  );
};

MyAvatar.propTypes = {
  displayName: PropTypes.string,
  opacity: PropTypes.bool,
  hover: PropTypes.bool,
  sx: PropTypes.object
};
