import { v4 as uuidv4 } from 'uuid';

import { COMMENT_PREFIX, isInsert } from 'helpers';

export const scrollToNewlyInsertedElement = (scrollRef, editorOffSet) => {
  const documentScrollTop = scrollRef.current.scrollTop;
  const editorHeight = scrollRef.current.clientHeight;
  const windowHeight = window.innerHeight;
  const windowOffSet = editorOffSet + windowHeight - editorHeight;
  if (windowOffSet < documentScrollTop || windowOffSet > documentScrollTop + windowHeight) {
    scrollRef.current.scrollTop = windowOffSet - windowHeight;
  }
};

export const checkIfBoldIsActiveOnNewListItemLine = quillEditor => {
  // give a unique id to newly created list items before saving is done
  const boldButtonActiveClass = [...document.getElementsByClassName('ql-bold ql-active')];
  const rangeFormats = quillEditor.getFormat();
  if (rangeFormats.listItem) {
    const listItemFormats = rangeFormats.listItem;
    const selectedListsId = JSON.parse(listItemFormats).listItemId;
    const elementList = Array.from(document.getElementsByTagName('list-item'));
    let counter = 0;
    for (const li of elementList) {
      if (li.getAttribute('listItemId') === selectedListsId) {
        if (!boldButtonActiveClass.length && counter > 0) {
          li.classList.remove('bold');
          li.setAttribute('listItemId', uuidv4());
        } else if (!boldButtonActiveClass.length && !counter) {
          counter++;
          li.setAttribute('listItemId', uuidv4());
        } else {
          li.classList.add('bold');
        }
      }
    }
  }
};

export const getSectionDimensionData = (handleSectionsDimensionData, setNumberingAndStyle) => {
  // Getting all elements from editor while replacing <ul> and <ol> elements with their children (<li>)
  // to display the readability indicator for each list item
  const editorElement = document.getElementsByClassName('ql-editor')[0];
  if (!editorElement) {
    return;
  }

  let htmlElements = [];
  Array.from(editorElement.children).forEach(el => {
    if (el.localName === 'ul' || el.localName === 'ol') {
      htmlElements = htmlElements.concat(Array.from(el.children));
    } else {
      htmlElements.push(el);
    }
  });

  const extractCommentIdsFromNode = node => {
    const childNodesArray = Array.from(node.childNodes);
    const commentClasses = childNodesArray.filter(item => item.className?.includes(COMMENT_PREFIX));
    const classList = commentClasses?.flatMap(item => Array.from(item.classList)) || [];
    const classListWithoutHighlighting = Array.from(
      classList.filter(classItem => classItem !== 'comment')
    );
    return [...new Set(classListWithoutHighlighting.flatMap(subArray => subArray))];
  };

  const sectionsDimensionsData = htmlElements.map(item => {
    return {
      isComment: extractCommentIdsFromNode(item),
      isTable: item.localName === 'table',
      offsetTop: item.offsetTop,
      height: item.clientHeight,
      headingTag:
        (item.classList.contains('ql-heading-0') && 1) ||
        (item.classList.contains('ql-heading-1') && 2) ||
        (item.classList.contains('ql-heading-2') && 3) ||
        false
    };
  });
  handleSectionsDimensionData(sectionsDimensionsData);
  setNumberingAndStyle(document.getElementsByTagName('list-item'));
};

const isInserted = change => {
  return change?.insert === 'true';
};

export const findEditsPositionFromText = documentContent => {
  const editsPositionAndLength = [];
  const stringLength = [];
  documentContent.ops.forEach(item => {
    if (item.insert === '\n') {
      stringLength.push(1);
      return;
    }
    if (item.attributes?.retainedText || item.attributes?.delete) {
      stringLength.push(item.insert.length);
      return;
    }
    if (isInsert(item.attributes)) {
      if (stringLength.length === 0) {
        editsPositionAndLength.push({
          index: 0,
          wordLength: item.insert.length,
          text: item.insert,
          isInserted: isInserted(item.attributes)
        });
        stringLength.push(item.insert.length);
        return;
      }
      const index = stringLength.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      editsPositionAndLength.push({
        index: index,
        wordLength: item.insert.length,
        text: item.insert,
        isInserted: isInserted(item.attributes)
      });
      stringLength.push(item.insert.length);
    }
  });
  return editsPositionAndLength;
};
