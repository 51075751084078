import React from 'react';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useQuery } from 'react-query';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { userService } from 'services';

export const Mentions = ({ textFieldValue, setTextFieldValue, commentInputField }) => {
  const { data } = useQuery(['users'], () => userService.getUsersBasicInfo(0, 100));
  const theme = useTheme();
  const [t] = useTranslation('common');

  const defaultMentionStyle = {
    backgroundColor: theme.palette.active.main,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 'inherit'
  };

  const defaultStyle = {
    control: {
      backgroundColor: theme.palette.gray.white,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightRegular
    },

    '&multiLine': {
      control: {
        fontFamily: theme.typography.fontFamilyPrimaryRegular
      },
      highlighter: {
        padding: 9
      },
      input: {
        padding: 9,
        borderRadius: '8px',
        lineHeight: 'inherit',
        outline: 'none'
      }
    },

    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1
      },
      input: {
        padding: 1
      }
    },

    suggestions: {
      top: 'calc(100%)',
      width: 'calc(100%)',
      list: {
        backgroundColor: theme.palette.gray.white,
        borderRadius: '8px',
        fontSize: theme.typography.pxToRem(12),
        fontFamily: theme.typography.fontFamilyPrimaryRegular,
        boxShadow: `0px 0px 10px 0px ${theme.palette.gray.light}`,
        maxHeight: '130px',
        overflowY: 'scroll'
      },
      item: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        padding: '5px 15px',
        fontSize: theme.typography.pxToRem(14),
        '&focused': {
          backgroundColor: theme.palette.gray.lightest
        }
      }
    }
  };

  const allUsers = data?.items?.map(item => {
    return {
      id: item.id,
      display: item.displayName
    };
  });

  const filteredUsers = (query, callback) => {
    const users = allUsers.filter(user => user.display.toLowerCase().includes(query.toLowerCase()));
    callback(users);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <MentionsInput
        inputRef={commentInputField}
        value={textFieldValue}
        onChange={e => setTextFieldValue(e.target.value)}
        placeholder={t('addComment')}
        style={defaultStyle}
        autoFocus>
        <Mention
          appendSpaceOnAdd
          data={filteredUsers}
          style={defaultMentionStyle}
          markup={'@[__display__](__id__)'}
        />
      </MentionsInput>
    </div>
  );
};

Mentions.propTypes = {
  textFieldValue: PropTypes.string,
  setTextFieldValue: PropTypes.func,
  commentInputField: PropTypes.object
};
