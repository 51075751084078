import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ParagraphLevelSuggestionsDialogTitle, Scrollbar } from 'components';
import { SimplificationLoader } from 'components/SimplificationLoader/SimplificationLoader';
import { MESSAGE_TYPES } from 'helpers';
import { useSimplifications } from 'hooks';
import { DocumentStateContext } from 'providers';
import { useStoreFields } from 'stores';

import { useStyles } from './ParagraphLevelSuggestionsDialog.css';
import { SimplificationItem } from '../SimplificationItem/SimplificationItem';

export const ParagraphLevelSuggestionsDialog = ({
  isFetchingSuggestions,
  sentenceSuggestions,
  setSentenceSuggestions,
  dialogRef,
  scrollRef,
  setSelectedText,
  selectedText,
  isRephraseSelected,
  suggestion,
  title = 'rephraseText',
  isOpen
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const selectionEndIndex = selectedText?.index + selectedText?.length;
  const [rephraseDialogPosition, setRephraseDialogPosition] = useState({ top: 0, left: 0 });

  const container = document.getElementById('editorContainer');
  const rect = container?.getBoundingClientRect();
  const rectContainerWidth = rect?.width + rect?.width * 0.08;
  const DIALOG_MAX_WIDTH = selectedText?.length > 60 ? 750 : 450;

  const { connection } = useContext(DocumentStateContext);
  const classes = useStyles(theme);
  const {
    isFetchingSelectedTextSimplifications,
    setIsFetchingSelectedTextSimplifications,
    setSimplifications,
    simplifications,
    quillEditor,
    setIsRephraseDialogOpen,
    setExpandedAccordion,
    setSelectedSimplification
  } = useStoreFields([
    'isFetchingSelectedTextSimplifications',
    'setIsFetchingSelectedTextSimplifications',
    'setSimplifications',
    'simplifications',
    'quillEditor',
    'setIsRephraseDialogOpen',
    'setExpandedAccordion',
    'setSelectedSimplification'
  ]);

  const {
    selectedTextSuggestions,
    setSelectedTextSuggestions,
    underlineSuggestions,
    highlightSelectedText
  } = useSimplifications({
    quillEditor
  });

  useEffect(() => {
    if (isOpen) {
      getDialogPosition();
    } else {
      highlightSelectedText(0, quillEditor?.getText().length, false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isRephraseSelected && selectedText) {
      connection?.on(
        MESSAGE_TYPES.RECEIVE_SELECTED_TEXT_SIMPLIFICATIONS,
        (resp, mlDataNotPresentResult) => {
          if (mlDataNotPresentResult) {
            setSelectedTextSuggestions([]);
            setSentenceSuggestions({});
            setIsFetchingSelectedTextSimplifications(false);
          } else if (
            selectedText?.index === resp.startIndex &&
            selectedText?.length === resp.endIndex - resp.startIndex
          ) {
            setSelectedTextSuggestions(resp);
            setSentenceSuggestions(resp.simplifications);
            setIsFetchingSelectedTextSimplifications(false);
          }
        }
      );
    }
  }, [selectionEndIndex]);

  const isFetchingCorrectSelectedTextSuggestions =
    isFetchingSelectedTextSimplifications ||
    (!isFetchingSelectedTextSimplifications &&
      selectedTextSuggestions?.startIndex !== selectedText?.index &&
      selectedTextSuggestions?.length !== selectedText?.length);

  const isFetchingData = isRephraseSelected
    ? isFetchingCorrectSelectedTextSuggestions
    : isFetchingSuggestions || suggestion?.targetId !== simplifications?.targetId;

  const simplificationContent = () => {
    return (
      <Scrollbar sx={{ maxHeight: '300px' }}>
        <DialogActions
          disableSpacing
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {sentenceSuggestions?.map((sentenceSuggestion, index) => {
            return (
              <SimplificationItem
                key={sentenceSuggestion.simplificationId}
                index={index}
                sentenceSuggestion={sentenceSuggestion}
                sentenceSuggestions={sentenceSuggestions}
                setSentenceSuggestions={setSentenceSuggestions}
                quillEditor={quillEditor}
                dialogRef={dialogRef}
                scrollRef={scrollRef}
                suggestion={suggestion}
                setExpandedAccordion={setExpandedAccordion}
                selectedText={selectedText}
              />
            );
          })}
        </DialogActions>
      </Scrollbar>
    );
  };

  const getDialogPosition = () => {
    const range = isRephraseSelected
      ? quillEditor?.getSelection()
      : { index: selectedText?.index, length: selectedText?.length };
    const rangePosition = calculateRangePosition(range);

    let left, top;

    const isSelectionSingleLine = rangePosition.height <= 25;

    if (rectContainerWidth && isSelectionSingleLine) {
      left =
        rectContainerWidth - DIALOG_MAX_WIDTH >= rangePosition.left
          ? rangePosition.left
          : rectContainerWidth - DIALOG_MAX_WIDTH;

      top = rangePosition.top + rangePosition.height;
    } else {
      left = 0;
      top = rangePosition.bottom;
    }

    setRephraseDialogPosition({ top, left });
  };

  const calculateRangePosition = range => {
    const lines = quillEditor?.getLines(range.index, range.length);

    // Handle single-line selection
    if (lines.length === 1) {
      return quillEditor?.getBounds(range);
    }

    // Handle multi-line selection
    const lastLine = lines[lines.length - 1];

    return quillEditor?.getBounds(quillEditor?.getIndex(lastLine), lastLine.length());
  };

  const handleDialogClose = () => {
    highlightSelectedText(selectedText?.index, selectedText?.length, false);
    if (isRephraseSelected) {
      setSelectedText(null);
      setSentenceSuggestions(null);
      setIsRephraseDialogOpen(false);
    } else {
      setSelectedText({ index: 0, length: 0 });
      setIsRephraseDialogOpen(false);
      setExpandedAccordion(null);
      setSimplifications({});
      setSelectedSimplification(null);
    }
    underlineSuggestions();

    connection?.invoke(MESSAGE_TYPES.CLOSE_SIMPLIFICATIONS);
  };

  return (
    <Dialog
      data-testid='paragraphLevelSuggestionsDialog'
      slotProps={{
        backdrop: {
          style: {
            opacity: 0
          }
        }
      }}
      sx={{
        position: 'absolute',
        '& .MuiDialog-container': {
          position: 'relative',
          top: `${rephraseDialogPosition.top}px`,
          left: `${rephraseDialogPosition.left}px`,
          height: 'auto',
          width: DIALOG_MAX_WIDTH,
          maxWidth: DIALOG_MAX_WIDTH,
          cursor: 'default'
        }
      }}
      hideBackdrop
      container={() => document.getElementById('popupParentContainer')}
      maxWidth={DIALOG_MAX_WIDTH.toString()}
      PaperProps={{ className: classes.paper }}
      open={isOpen}
      onClick={e => e.stopPropagation()}
      onClose={handleDialogClose}>
      <ParagraphLevelSuggestionsDialogTitle handleDialogClose={handleDialogClose} title={title} />
      <DialogContent
        ref={dialogRef}
        sx={{
          '&.MuiDialogContent-root': {
            padding: 0
          }
        }}>
        {isFetchingData ? (
          <SimplificationLoader height={30} my={5} fontSize={14} />
        ) : (
          simplificationContent()
        )}
        {!isFetchingData && sentenceSuggestions?.length === 0 && (
          <Box
            data-testid='noSuggestionsBox'
            sx={{
              height: '110px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography
              data-testid='noSuggestionsText'
              variant='body3'
              sx={{
                fontFamily: theme.typography.fontFamilyPrimaryRegular
              }}>
              {t('noSuggestionsAvailable')}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

ParagraphLevelSuggestionsDialog.propTypes = {
  isFetchingSuggestions: PropTypes.bool,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  dialogRef: PropTypes.object,
  scrollRef: PropTypes.object,
  setSelectedText: PropTypes.func,
  selectedText: PropTypes.object,
  isRephraseSelected: PropTypes.bool,
  suggestion: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool
};
