import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  filterProperty: props => ({
    display: 'flex',
    flexDirection: 'row',
    height: '50px',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '10px',
    borderBottom: '1px solid ' + props.palette.gray.light
  }),
  dropdownStyle: props => ({
    width: '260px',
    height: '40px',
    border: '1px solid ' + props.palette.gray.light,
    borderRadius: '8px',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    color: props.palette.gray.main,
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(14)
  }),
  filterOptions: props => ({
    color: props.palette.common.black,
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(14),
    '&:hover': {
      backgroundColor: props.palette.gray.lightest + '!important',
      color: props.palette.primary.main
    },
    '&.Mui-selected': {
      backgroundColor: props.palette.gray.lightest,
      color: props.palette.primary.main
    },
    height: '40px'
  }),
  menuItems: props => ({
    fontFamily: props.typography.fontFamilySecondaryMedium,
    '&:hover': {
      backgroundColor: props.palette.gray.lightest,
      color: props.palette.primary.main
    },
    height: '40px',
    justifyContent: 'space-between'
  }),
  searchStyles: props => ({
    width: '260px',
    height: '40px',
    borderRadius: '8px',
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(14),
    border: `1px solid ${props.palette.gray.light}`,
    marginLeft: '10px',
    marginBottom: '10px'
  }),
  stackContainer: props => ({
    width: '140px',
    height: '50px',
    border: '1px solid ' + props.palette.gray.light,
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    gap: '10px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid ' + props.palette.primary.main
    }
  }),
  stackStyle: props => ({
    backgroundColor: props.palette.common.white,
    width: '290px',
    border: '1px solid ' + props.palette.gray.light,
    borderRadius: '8px',
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    marginTop: '5px'
  }),
  filterCategories: props => ({
    fontFamily: props.typography.fontFamilySecondaryMedium,
    '&:hover': {
      backgroundColor: props.palette.gray.lightest,
      color: props.palette.primary.main
    },
    height: '45px',
    display: 'flex',
    justifyContent: 'space-between'
  })
});
