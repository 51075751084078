import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Menu } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LightTooltip, LabelMenu, SvgIconStyle } from 'components';
import { removeLabels } from 'resources';

const useStyles = makeStyles(theme => ({
  bulkIcon: {
    backgroundColor: theme.palette.gray.white,
    '&:hover': { backgroundColor: theme.palette.gray.light },
    marginRight: '15px',
    width: '2rem',
    height: '2rem'
  }
}));

export const RemoveLabelMenu = ({ selectedFilesLabels, handleBulkLabelChange }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedLabels([]);
  };

  const handleApplyButtonClick = () => {
    handleBulkLabelChange(selectedLabels);
    handleClose();
  };

  return (
    <>
      <LightTooltip title={t('removeLabelsMultiple')}>
        <IconButton
          data-testid='removeLabelButton'
          disableRipple
          className={classes.bulkIcon}
          size='medium'
          onClick={e => setAnchorEl(e.target)}>
          <SvgIconStyle
            src={removeLabels}
            sx={{ color: theme.palette.primary.main, width: '13px', height: '20px' }}
          />
        </IconButton>
      </LightTooltip>
      {!!anchorEl && (
        <Menu open anchorEl={anchorEl} onClose={handleClose}>
          <LabelMenu
            selectedLabels={selectedLabels}
            setSelectedLabels={setSelectedLabels}
            showBackButtonItem={false}
            showApplyAndClearAllButtons
            applyMultiLabelSelection={handleApplyButtonClick}
            labelsOnSelectedDocuments={selectedFilesLabels}
          />
        </Menu>
      )}
    </>
  );
};

RemoveLabelMenu.propTypes = {
  selectedFilesLabels: PropTypes.array,
  handleBulkLabelChange: PropTypes.func
};
