import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { MyAvatar, LightTooltip, Icons } from 'components';
import { UserContext } from 'providers';

import { AccountSwitchDropdown } from './AccountSwitchDropdown';

export const AccountSwitchDialog = ({ isOpen, setIsOpen }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const { user, signOutAsync } = useContext(UserContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles({
    paper: {
      height: '665px',
      width: '665px'
    },
    closeIcon: {
      width: '25px',
      height: '25px',
      backgroundColor: theme.palette.gray.lighter,
      borderRadius: '50%',
      cursor: 'pointer',
      color: theme.palette.gray.main,
      padding: '0.3rem'
    },
    dialogTitle: {
      color: theme.palette.gray.dark,
      fontFamily: theme.typography.fontFamilySecondaryBold,
      fontSize: theme.typography.pxToRem(isMobile ? 24 : 30),
      alignSelf: 'flex-start',
      paddingLeft: isMobile && '0px'
    },
    avatarFrame: {
      height: '73px',
      width: '73px',
      border: '1px dashed',
      borderColor: theme.palette.quaternary.dark,
      borderRadius: '50%'
    },
    dialogContent: {
      paddingLeft: isMobile ? '0px' : '25px',
      paddingBottom: '25px',
      paddingRight: !isMobile && '25px',
      flexDirection: 'row',
      alignItems: 'center'
    },
    textStyle: {
      paddingLeft: !isMobile && '25px',
      color: theme.palette.gray.main,
      fontFamily: theme.typography.fontFamilyPrimaryMedium,
      fontSize: theme.typography.pxToRem(12)
    },
    iconContainer: {
      width: '36px',
      height: '36px',
      alignContent: 'center',
      cursor: 'pointer',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': { backgroundColor: theme.palette.gray.lightest }
    }
  });
  const classes = useStyles();

  const handleLogout = async () => {
    try {
      await signOutAsync();
    } catch (error) {
      enqueueSnackbar(t('unableToLogout'), { variant: 'error' });
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} classes={{ paper: classes.paper }}>
      <Grid sx={{ p: 3, display: 'flex', alignSelf: 'flex-end' }}>
        <IconButton
          aria-label='close'
          className={classes.closeIcon}
          onClick={() => setIsOpen(false)}>
          <CloseIcon style={{ width: '16px', height: '16px' }} />
        </IconButton>
      </Grid>
      <Grid
        sx={{
          padding: isMobile ? '40px 25px 25px 25px' : '50px',
          marginTop: '-80px'
        }}>
        <DialogTitle className={classes.dialogTitle}>{t('switchAccount')}</DialogTitle>
        <Grid className={classes.dialogContent} sx={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.avatarFrame}>
            <MyAvatar
              displayName={user.displayName}
              sx={{ width: 57, height: 57, margin: 'auto', mt: '7px' }}
            />
          </div>
          <div
            style={{
              width: 'calc(100% - 73px)',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between'
            }}>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                ml: '20px',
                mt: '5px'
              }}>
              <Typography
                variant='body1'
                sx={{
                  color: theme.palette.primary.main,
                  fontFamily: theme.typography.fontFamilyPrimarySemibold,
                  fontSize: '16px'
                }}>
                {user?.displayName}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  color: theme.palette.gray.main,
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  fontSize: '14px'
                }}>
                {user?.tenants.find(ten => ten.isDefault).name}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  color: theme.palette.gray.main,
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  fontSize: '14px'
                }}>
                {user?.roles[0].name}
              </Typography>
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                ml: isMobile && '20px'
              }}>
              <LightTooltip title={t('settings')}>
                <IconButton
                  className={classes.iconContainer}
                  onClick={() => {
                    navigate('/profile');
                    setIsOpen(false);
                  }}>
                  <Icons iconName={'accountIcon'} fill={theme.palette.primary.main} />
                </IconButton>
              </LightTooltip>
              <LightTooltip title={t('logout')}>
                <IconButton className={classes.iconContainer} onClick={() => handleLogout()}>
                  <Icons iconName={'logout'} fill={theme.palette.primary.main} />
                </IconButton>
              </LightTooltip>
            </Grid>
          </div>
        </Grid>
        <Typography className={classes.textStyle}>{t('accounts')}</Typography>
        <AccountSwitchDropdown />
      </Grid>
    </Dialog>
  );
};

AccountSwitchDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};
