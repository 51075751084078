import { useContext, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { customAlphabet } from 'nanoid';

import {
  COMMENT_PREFIX,
  changeActiveCommentsHighlighting,
  changeCommentsHighlighting,
  editorSidebarOptions,
  getCommentIdsWithoutPrefix,
  getElementsWithoutCommentClasses,
  MESSAGE_TYPES,
  scrollToElementIfNeeded,
  switchCommentFormatOnParagraphLevel,
  highlighParagraphViaCommentTag,
  removeCommentFormatOnParagraph,
  removePreviousSelectionWhileAddingComments
} from 'helpers';
import { DocumentStateContext } from 'providers';
import { useStoreFields } from 'stores';

export const useCommentingData = ({
  selectedTab,
  setSelectedTab,
  redEditor,
  scrollRef,
  quillEditor
}) => {
  const [comments, setComments] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [commentId, setCommentId] = useState('');
  const [commentSelection, setCommentSelection] = useState({ index: -1, length: -1 });
  const [isCommentingActive, setIsCommentingActive] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState('');
  const [selectedCommentTag, setSelectedCommentTag] = useState(-1);
  const commentInputField = useRef(null);
  const paragraphLevelCommentIdsRef = useRef([]);
  const isCommentTagClickedRef = useRef(false);

  const theme = useTheme();
  const { connection } = useContext(DocumentStateContext);

  const { isReconnecting, setIsParagraphDialogOpen, previouslyClickedSection } = useStoreFields([
    'isReconnecting',
    'setIsParagraphDialogOpen',
    'previouslyClickedSection'
  ]);

  const [searchParams] = useSearchParams();
  const queryCommentId = searchParams.get('commentId');

  //quill's sanitation method will strip off attribute values containing "-" character
  const ALLOWED_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const isSimplificationTabSelected = selectedTab === editorSidebarOptions.SIMPLIFICATION;
  const isCommentTabSelected = selectedTab === editorSidebarOptions.COMMENTS;
  const addingCommentInProgress = commentSelection?.length !== -1 && isCommentingActive;

  //when selection changes, unique id is generated, if selected length is bigger than 0, comment class and style attributes are added to the selection, otherwise attributes are added to the entire paragraph.
  // On every selection comment input field is focused.
  const formatCommentText = () => {
    const id = customAlphabet(ALLOWED_CHARACTERS, 10)();
    setCommentId(id);

    const { index, length } = commentSelection.length
      ? commentSelection
      : switchCommentFormatOnParagraphLevel(quillEditor, commentSelection);

    addCommentHighlightingToASelection(index, length);
    addCommentIdToASelection(index, length, id);
    commentInputField.current?.focus();
  };

  const addCommentIdToASelection = (startIndex, selectionLength, id) => {
    quillEditor?.formatText(
      startIndex,
      selectionLength,
      'comment',
      `${COMMENT_PREFIX}${id}`,
      'user'
    );
  };

  const addCommentHighlightingToASelection = (startIndex, selectionLength) => {
    quillEditor?.formatText(
      startIndex,
      selectionLength,
      'highlightComment',
      theme.palette.gray.light
    );
  };

  const getComments = ids => {
    connection
      ?.invoke(MESSAGE_TYPES.GET_COMMENTS, ids)
      .then(resp => {
        setComments(resp);
        ids ?? setAllComments(resp);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const clearCommentsSelection = () => {
    if (!isCommentTagClickedRef.current) {
      return;
    }
    setSelectedCommentTag(-1);
    highlighParagraphViaCommentTag(paragraphLevelCommentIdsRef.current[0], 'remove');
    isCommentTagClickedRef.current = false;
    getComments(null);
  };

  const handleOnCommentCardClick = id => {
    if (commentId) {
      changeActiveCommentsHighlighting(false, commentId);
      changeCommentsHighlighting(true, commentId);
    }
    setCommentId(id);
    changeActiveCommentsHighlighting(true, id);
    const activeHighlight = redEditor.current.getElementsByClassName('activeComment');
    scrollToElementIfNeeded(activeHighlight[0], scrollRef);
  };

  useEffect(() => {
    if (isCommentingActive && paragraphLevelCommentIdsRef.current.length) {
      highlighParagraphViaCommentTag(paragraphLevelCommentIdsRef.current[0], 'remove');
    }
    if (!isCommentingActive) {
      setSelectedTab(editorSidebarOptions.SIMPLIFICATION);
    }
  }, [isCommentingActive]);

  // if comment indicator is already active second click on it will reset the comment card list (show all the comments) and remove the text highlight, if another comment indicator is selected text highlight from previous one will be removed and new applied on current paragraph and comment list filtered according to ids provided
  const onCommentIndicatorClick = ids => {
    changeActiveCommentsHighlighting(false, commentId);
    setSelectedCommentId('');
    if (addingCommentInProgress) {
      removeCommentFormatOnParagraph(quillEditor, commentSelection);
      const elements = document.getElementsByClassName(`${COMMENT_PREFIX}${commentId}`);
      getElementsWithoutCommentClasses(elements, commentId);
      setCommentSelection({ index: -1, length: -1 });
    }
    const idsWithoutPrefix = getCommentIdsWithoutPrefix(ids);
    if (isCommentTagClickedRef.current && _.isEqual(ids, paragraphLevelCommentIdsRef.current)) {
      getComments(null);
      isCommentTagClickedRef.current = false;
    } else {
      paragraphLevelCommentIdsRef.current = ids;
      isCommentTagClickedRef.current = true;
      getComments(idsWithoutPrefix);
      setSelectedTab(editorSidebarOptions.COMMENTS);
    }
  };

  const removeParagraphSuggestionHighlight = () => {
    if (previouslyClickedSection) {
      quillEditor?.formatText(
        previouslyClickedSection?.startIndex,
        previouslyClickedSection?.endIndex - previouslyClickedSection?.startIndex,
        'paragraph_suggestion',
        false
      );
    }
  };

  useEffect(() => {
    setCommentSelection({ index: -1, length: -1 });
  }, [isReconnecting]);

  useEffect(() => {
    comments.forEach(comment => {
      if (isCommentingActive) {
        changeCommentsHighlighting(true, comment.id);
      } else {
        changeCommentsHighlighting(false, null, comment.id);
        changeActiveCommentsHighlighting(false, commentId);
      }
    });
  }, [isCommentingActive, comments, commentSelection]);

  useEffect(() => {
    if (isCommentingActive && commentSelection.index !== -1 && !isReconnecting) {
      formatCommentText();
    }
  }, [commentSelection]);

  useEffect(() => {
    if (isCommentingActive && paragraphLevelCommentIdsRef.current.length) {
      highlighParagraphViaCommentTag(paragraphLevelCommentIdsRef.current[0], 'remove');
    }
  }, [isCommentingActive]);

  useEffect(() => {
    if (isSimplificationTabSelected) {
      paragraphLevelCommentIdsRef.current.length &&
        highlighParagraphViaCommentTag(paragraphLevelCommentIdsRef.current[0], 'remove');
      setIsCommentingActive(false);
      setSelectedCommentTag(-1);
      removePreviousSelectionWhileAddingComments(commentSelection, quillEditor, commentId);
      setCommentSelection({ index: -1, length: -1 });
    }
    if (isCommentTabSelected) {
      setIsParagraphDialogOpen(false);
      setIsCommentingActive(true);
      getComments(null);
      removeParagraphSuggestionHighlight();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (comments.length > 0 && queryCommentId && selectedCommentId) {
      handleOnCommentCardClick(selectedCommentId);
    }
  }, [comments, selectedCommentId, queryCommentId]);

  return {
    comments,
    getComments,
    allComments,
    commentId,
    setCommentId,
    commentSelection,
    setCommentSelection,
    selectedCommentId,
    setSelectedCommentId,
    isCommentingActive,
    setIsCommentingActive,
    queryCommentId,
    commentInputField,
    selectedCommentTag,
    setSelectedCommentTag,
    isCommentTagClickedRef,
    paragraphLevelCommentIdsRef,
    onCommentIndicatorClick,
    handleOnCommentCardClick,
    clearCommentsSelection
  };
};
