import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Icons, LoadingAnimation } from 'components';

export const PrimaryButton = forwardRef(
  (
    {
      isDisabled,
      handleClick,
      buttonText,
      width,
      height = '50px',
      route,
      variant = 'black',
      icon,
      loading = false,
      type,
      fontSize = 16,
      fontFamily,
      iconStrokeColor = '',
      marginBottom,
      dataCy,
      isActionInProgress = false,
      endIcon,
      loadingAnimationWidth = 14,
      loadingAnimationHeight = 14,
      padding,
      fill,
      showBorder = true,
      borderRadius,
      lightenBorder = false,
      dataTestId = '',
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const [t] = useTranslation('common');

    const useStyles = makeStyles({
      blackButton: {
        backgroundColor: theme.palette.common.black,
        '&:hover': { backgroundColor: theme.palette.gray.main },
        color: theme.palette.gray.white,
        width: width,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.gray.light,
          color: theme.palette.gray.white
        }
      },
      whiteButton: {
        marginRight: 1.5,
        border:
          showBorder &&
          `0.5px solid ${lightenBorder ? theme.palette.gray[400] : theme.palette.gray.main}`,
        backgroundColor: theme.palette.gray.white,
        '&:hover': {
          backgroundColor: theme.palette.gray.lighter
        },
        color: theme.palette.gray.darker,
        width: width,
        '&.Mui-disabled': {
          border: '0.5px solid ' + theme.palette.gray.light,
          backgroundColor: theme.palette.gray.white,
          color: theme.palette.gray.light,
          '& .MuiButton-startIcon': {
            stroke: theme.palette.gray.light
          }
        }
      },
      grayButton: {
        backgroundColor: theme.palette.gray.darker,
        color: theme.palette.gray.medium,
        width: width,
        '&:hover': {
          backgroundColor: theme.palette.gray.main
        }
      },
      transparentButton: {
        backgroundColor: theme.palette.gray.lighter,
        '&:hover': {
          backgroundColor: theme.palette.gray.light
        },
        color: theme.palette.gray.main
      },
      darkButton: {
        backgroundColor: theme.palette.gray.main,
        color: theme.palette.gray.white,
        '&:hover': {
          backgroundColor: theme.palette.gray.main,
          opacity: '50%'
        }
      }
    });

    const classes = useStyles();
    let style;

    switch (variant) {
      case 'black':
        style = classes.blackButton;
        break;
      case 'white':
        style = classes.whiteButton;
        break;
      case 'gray':
        style = classes.grayButton;
        break;
      case 'transparent':
        style = classes.transparentButton;
        break;
      case 'dark':
        style = classes.darkButton;
        break;
      default:
        style = classes.blackButton;
        break;
    }

    const isSubmitType = type === 'submit';
    const CustomComponent = isSubmitType ? LoadingButton : Button;

    return (
      <CustomComponent
        {...props}
        ref={ref}
        data-testid={dataTestId}
        disableRipple
        data-cy={dataCy}
        disabled={isDisabled}
        onClick={handleClick}
        className={style}
        variant='contained'
        type={type}
        loading={isSubmitType ? loading : null}
        to={route}
        sx={{
          height: height,
          minHeight: height,
          marginBottom: marginBottom,
          textTransform: 'none',
          padding: padding,
          borderRadius: borderRadius
        }}
        startIcon={
          isActionInProgress ? (
            <LoadingAnimation
              height={loadingAnimationHeight}
              width={loadingAnimationWidth}
              marginRight={buttonText ? 5 : 0}
            />
          ) : (
            icon && (
              <div style={{ marginRight: buttonText ? '5px' : '0px' }}>
                <Icons
                  fill={fill}
                  iconName={icon}
                  strokeColor={iconStrokeColor}
                  style={{
                    minWidth: '16px',
                    minHeight: '16px'
                  }}
                />
              </div>
            )
          )
        }
        endIcon={
          endIcon && (
            <div style={{ marginRight: buttonText ? '5px' : '0px' }}>
              <Icons
                iconName={endIcon}
                strokeColor={iconStrokeColor}
                style={{
                  minWidth: '16px',
                  minHeight: '16px'
                }}
              />
            </div>
          )
        }
        component={isSubmitType ? null : RouterLink}>
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            fontSize: theme.typography.pxToRem(fontSize),
            fontFamily: fontFamily ?? theme.typography.fontFamilySecondaryMedium
          }}>
          {t(buttonText)}
        </Typography>
      </CustomComponent>
    );
  }
);

PrimaryButton.displayName = 'PrimaryButton';

PrimaryButton.propTypes = {
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  buttonText: PropTypes.string,
  width: PropTypes.string,
  route: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.any,
  loading: PropTypes.bool,
  type: PropTypes.string,
  iconStrokeColor: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.number,
  fontFamily: PropTypes.any,
  marginBottom: PropTypes.string,
  dataCy: PropTypes.string,
  isActionInProgress: PropTypes.bool,
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  padding: PropTypes.string,
  loadingAnimationWidth: PropTypes.number,
  loadingAnimationHeight: PropTypes.number,
  showBorder: PropTypes.bool,
  fill: PropTypes.string,
  borderRadius: PropTypes.string,
  lightenBorder: PropTypes.bool,
  dataTestId: PropTypes.string
};
