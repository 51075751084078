import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CircleIcon from '@mui/icons-material/Circle';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomTooltip } from 'components';
import {
  checkFeatureFlag,
  COMPLEX_SENTENCES,
  COMPLEX_WORDS,
  COMPLEXITY_SCORE,
  featureFlagsEnum,
  getIntelligibilityLevelDescription
} from 'helpers';
import { UserContext } from 'providers';

import { CircularProgressDiagram } from './CircularProgressDiagram';
import { useStyles } from './IntelligibilitySidebar.css';
import { MetricsRating } from './MetricsRating';

export const IntelligibilityScoreInfo = ({ intelligibilityData }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);
  const { featureFlags } = useContext(UserContext);

  const showPersonasScorings = checkFeatureFlag(featureFlags, featureFlagsEnum.PERSONAS_SCORING);

  const renderIntelligibilityScores = (title, description, value, circleColor, isPercentage) => {
    return (
      <Grid sx={{ mt: '5px' }}>
        <Grid className={classes.textPositionStyle}>
          <Typography
            variant='caption'
            sx={{
              color: theme.palette.primary.main,
              fontFamily: theme.typography.fontFamilyPrimarySemibold,
              fontWeight: '500'
            }}>
            {t(title)}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.gray.dark,
              fontFamily: theme.typography.fontFamilyPrimaryMedium,
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 500
            }}>
            {value}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            direction: 'row',
            alignItems: 'center'
          }}>
          <CircleIcon
            sx={{ width: '10px', height: '10px', color: theme.palette[circleColor].dark }}
          />
          {isPercentage && (
            <Grid sx={{ display: 'flex' }}>
              <Typography
                variant='body2'
                sx={{
                  ml: '10px',
                  color: theme.palette.gray.main,
                  fontFamily: theme.typography.fontFamilyPrimaryRegular
                }}>
                {`${t('from')} `}
                {description}
              </Typography>
              <Typography variant='body2' className={classes.textStyle} sx={{ ml: '5px' }}>
                {t('changesMade', { count: description })}
              </Typography>
            </Grid>
          )}
          {!isPercentage && (
            <Typography variant='body2' className={classes.textStyle} sx={{ ml: '10px' }}>
              {t('intelligibility_level_' + description)}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack>
      <Grid className={classes.textPositionStyle}>
        <Typography
          variant='subtitle2'
          sx={{
            color: theme.palette.gray.dark,
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            fontSize: theme.typography.pxToRem(14)
          }}>
          {t('intelligibilityScore')}
        </Typography>
        <Grid sx={{ display: 'flex', alignItems: 'center', mr: '20px' }}>
          <CircleIcon
            sx={{
              width: '10px',
              height: '10px',
              color:
                theme.palette[`intelligibility_Level${intelligibilityData.intelligibilityLevel}`]
                  .dark
            }}
          />
          <Typography
            variant='body2'
            style={{
              marginLeft: '10px',
              color: theme.palette.gray.main,
              fontFamily: theme.typography.fontFamilySecondaryMedium,
              fontSize: theme.typography.pxToRem(14)
            }}>
            {t('intelligibility_level_' + intelligibilityData.intelligibilityLevel)}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '30px'
          }}>
          <Grid>
            <Typography
              variant='body2'
              sx={{
                display: 'inline',
                color: theme.palette.gray.dark,
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                fontSize: theme.typography.pxToRem(12)
              }}>
              <Trans components={{ b: <strong /> }}>
                {getIntelligibilityLevelDescription(t, intelligibilityData.score)}
              </Trans>
            </Typography>
            <div style={{ display: 'inline-block', marginLeft: '5px', verticalAlign: 'middle' }}>
              <CustomTooltip tooltipContent={t('scoreDisclaimer')} />
            </div>
          </Grid>
          <CircularProgressDiagram intelligibilityData={intelligibilityData} />
        </Grid>
        <Grid sx={{ marginTop: '30px' }}>
          {renderIntelligibilityScores(
            'intelligibilityImprovement',
            intelligibilityData?.numberOfEdits,
            `${intelligibilityData.intelligibilityImprovements?.toLocaleString('en-US')}%`,
            'intelligibility_Level1',
            true
          )}
        </Grid>
        <Grid style={{ marginTop: '20px' }}>
          {renderIntelligibilityScores(
            'originalIntelligibilityScore',
            intelligibilityData.baselineIntelligibilityLevel,
            intelligibilityData.baselineIntelligibilityScore,
            'intelligibility_Level' + intelligibilityData.baselineIntelligibilityLevel
          )}
        </Grid>
        <Divider sx={{ m: '20px 0px' }} />
        <Typography variant='body2' className={classes.headerStyle}>
          {t('complexityMetrics')}
        </Typography>
        <MetricsRating
          metricType={COMPLEXITY_SCORE}
          textMetric={intelligibilityData.textMetrics?.cefr}
        />
        <MetricsRating
          metricType={COMPLEX_SENTENCES}
          textMetric={{
            ...intelligibilityData.textMetrics?.complexSentences,
            value: intelligibilityData.textMetrics?.complexSentences.value + '%'
          }}
          isComplexWordsAndSentences={true}
        />
        <MetricsRating
          metricType={COMPLEX_WORDS}
          textMetric={{
            ...intelligibilityData.textMetrics?.complexWords,
            value: intelligibilityData.textMetrics?.complexWords.value + '%'
          }}
          isComplexWordsAndSentences={true}
        />
        {showPersonasScorings && (
          <div>
            <Divider sx={{ m: '20px 0px' }} />
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Typography variant='body2' className={classes.headerStyle}>
                {t('scoringForPersonas')}
              </Typography>
              <CustomTooltip tooltipContent={t('personasDescription')} />
            </div>
            <Stack direction='column' sx={{ justifyContent: 'space-between', marginTop: '10px' }}>
              {intelligibilityData?.personas?.map(persona => {
                return (
                  <Stack
                    key={persona.id}
                    direction='row'
                    sx={{ justifyContent: 'space-between', marginTop: '8px' }}>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          fontSize: theme.typography.pxToRem(12),
                          fontFamily: theme.typography.fontFamilyPrimaryRegular,
                          color: theme.palette.primary.main
                        }}>
                        {persona.name}
                      </Typography>
                      <CustomTooltip tooltipContent={persona.description} />
                    </div>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <CircleIcon
                        sx={{
                          width: '8px',
                          height: '8px',
                          color:
                            theme.palette[`intelligibility_Level${persona.intelligibilityLevel}`]
                              .dark
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: theme.typography.pxToRem(14),
                          fontFamily: theme.typography.fontFamilyPrimaryRegular,
                          color: theme.palette.primary.main
                        }}>
                        {t('intelligibility_level_' + persona.intelligibilityLevel)}
                      </Typography>
                    </div>
                  </Stack>
                );
              })}
            </Stack>
          </div>
        )}
      </Grid>
    </Stack>
  );
};

IntelligibilityScoreInfo.propTypes = {
  intelligibilityData: PropTypes.object
};

export default IntelligibilityScoreInfo;
