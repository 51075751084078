import { makeStyles } from '@mui/styles';

const important = value => {
  return `${value} !important`;
};

export const useStyles = makeStyles({
  editorContainer: props => ({
    backgroundColor: 'transparent',
    position: 'relative',
    '& .ql-snow .ql-tooltip': {
      padding: '7px 12px'
    },
    '& .ql-tooltip': {
      transform: props => `translate(${props[0]}px, ${props[1]}px)`,
      backgroundColor: props.palette.primary.main,
      borderRadius: '22px',
      color: props.palette.gray.white,
      height: '44px',
      overflow: 'visible'
    },
    '& .ql-tooltip-arrow': {
      borderBottom: important('transparent')
    },
    '& .separator': {
      padding: important('0'),
      width: important('1px'),
      margin: important('0 6px')
    },
    '& button.separator': {
      pointerEvents: 'none'
    },
    '& p, & h1, & h2, & h3, & ul, & ol': {
      marginTop: '2rem'
    },
    marginRight: '8%'
  }),
  reloadParagraphSuggestions: props => ({
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: 9999,
    left: '-33px', // 18px width of element + 15px margin from text
    border: `1px solid ${props.palette.gray.dark}`,
    position: 'absolute',
    transitionDuration: '300ms',
    transitionProperty: 'top'
  })
});
