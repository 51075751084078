import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Exclusions`;

const getCustomExclusions = () => {
  const url = `${baseUrl}/custom`;
  return axios.get(url).then(response => {
    return response.data;
  });
};

export const exclusionService = {
  getCustomExclusions
};
