export const permissions = {
  ANALYTICS_READ: 'analytics.read',
  APP_ABOUT: 'app.about',
  LABELS_CREATE: 'labels.create',
  LABELS_DELETE: 'labels.delete',
  LABELS_EDIT: 'labels.edit',
  LABELS_READ: 'labels.read',
  PRODUCTS_CREATE: 'products.create',
  PRODUCTS_EXPORT_ALL_TENANTS: 'products.exportAllTenants',
  PRODUCTS_READ: 'products.read',
  PRODUCTS_UPDATE: 'products.update',
  READABILITY_EXPORT: 'readability.export',
  READABILITY_READ: 'readability.read',
  READABILITY_RECALCULATION: 'readability.recalculation',
  REPORTING_CLEAR: 'reporting.clear',
  REPORTING_RELOAD: 'reporting.reload',
  SETTINGS_BILLING: 'settings.billing',
  STYLE_GUIDE_READ: 'styleguide.read',
  SUPER_ADMIN_USER_ALL_ACCESS: 'superAdmin.allAccess',
  USERS_CREATE: 'users.create',
  USERS_DELETE: 'users.delete',
  USERS_READ: 'users.read',
  USERS_RESET_AUTHENTICATION: 'users.resetAuthentication',
  USERS_SHOW_IMPACT_SCORE: 'users.showImpactScore',
  USERS_TRACK_ML_REQUESTS: 'users.trackMLRequests',
  USERS_UPDATE: 'users.update',
  USERS_UPDATE_AUTH_PROVIDER: 'users.updateAuthProvider'
};

export const checkPermission = (assignedPermissions, neededPermissions, allNeeded) => {
  if (!assignedPermissions || assignedPermissions.length === 0) {
    return false;
  }

  if (assignedPermissions.includes(permissions.SUPER_ADMIN_USER_ALL_ACCESS)) {
    return true;
  }

  if (!neededPermissions) {
    return true;
  }

  if (Array.isArray(neededPermissions)) {
    return allNeeded
      ? neededPermissions.every(perm => assignedPermissions.includes(perm))
      : neededPermissions.some(perm => assignedPermissions.includes(perm));
  }

  return assignedPermissions.includes(neededPermissions);
};
