import ReactQuill from 'react-quill';

import { COMMENT_PREFIX } from 'helpers';

const Parchment = ReactQuill.Quill.import('parchment');

export class CommentAttribute extends Parchment.Attributor.Class {
  constructor(attrName = 'comment', keyName = 'comment') {
    super(attrName, keyName, { scope: Parchment.Scope.INLINE_ATTRIBUTE });
  }

  // add id to the comment class attributor
  add(node, value) {
    if (!this.canAdd(node, value)) {
      return false;
    }

    // The node could include the part of the document before the part for the current comment, containing classes of other comments.
    // If all items in value contain the 'comments' prefix, they represent the newly added comments. In that case, add new comments to the node's class list.
    // In all other cases clear the node's list and add only items from the value to the node's class list ( to split the node based on comments).
    const ids = Array.isArray(value) ? value : [value];

    if (ids.some(id => !id.includes(COMMENT_PREFIX))) {
      [...node.classList]
        .filter(nodeClass => nodeClass.includes(COMMENT_PREFIX))
        .forEach(commentClass => {
          node.classList.remove(commentClass);
        });
    }

    const formattedIds = ids.map(id =>
      id.includes(COMMENT_PREFIX) ? id.replace(COMMENT_PREFIX, '') : id
    );

    formattedIds.forEach(id => {
      node.classList.add(`${this.keyName}-${id}`);
    });

    return true;
  }

  //passes array of ids to quill internals
  value(node) {
    const prefix = `${this.keyName}-`;
    if (!node.classList.length) {
      return;
    }
    const list = Array.from(node.classList)
      .filter(listItem => listItem.startsWith(prefix))
      .map(listItem => listItem.slice(prefix.length));
    return list.length > 0 ? list : null;
  }

  // remove comment class from class attribute
  remove(node) {
    if (
      node.classList.length === 1 ||
      (node.classList.length === 2 && node.classList.contains('comment'))
    ) {
      node.removeAttribute('class');
    }
  }
}

ReactQuill.Quill.register('formats/comment', new CommentAttribute());
