import React, { useState } from 'react';

import { Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';
import { ReactComponent as CheckboxUnchecked } from 'resources/images/checkboxUnchecked.svg';

const useStyles = makeStyles(() => ({
  checkbox: {
    height: '18px',
    width: '18px'
  }
}));

export const CustomCheckbox = ({ checked, onChange, disabled, dataCy, dataTestId }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Checkbox
      disableRipple
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classes.checkbox}
      icon={
        <div style={{ maxWidth: 'none', display: 'flex' }}>
          <CheckboxUnchecked
            data-testid='checkboxUncheckedIcon'
            style={{
              fill: isHovered ? theme.palette.gray.lighter : theme.palette.gray[50],
              stroke: theme.palette.gray[400]
            }}
          />
        </div>
      }
      checkedIcon={
        <div style={{ maxWidth: 'none', display: 'flex' }}>
          <Icons iconName={'checkboxCheckedIcon'} width='18px' height='18px' />
        </div>
      }
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      data-cy={dataCy}
      data-testid={dataTestId}
    />
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
  dataTestId: PropTypes.string
};
