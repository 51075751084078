import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
  CustomCheckbox,
  Icons,
  LightTooltip,
  PrimaryButton,
  Scrollbar,
  SearchWrapper
} from 'components';
import { filterSearchedProperties, handleSearch } from 'helpers';
import { handleLabelChange, isLabelMenuItemChecked } from 'helpers/methods.js';
import { UserContext } from 'providers';

import { useStyles } from './LabelSelection.css.js';
import './Labels.css';

export const LabelMenu = ({
  existingLabels,
  selectedLabels,
  setSelectedLabels,
  setIsChangeLabelInProgress,
  showBackButtonItem = true,
  showApplyAndClearAllButtons = false,
  applyMultiLabelSelection,
  labelsOnSelectedDocuments
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [t] = useTranslation('common');

  const [search, setSearch] = useState('');
  const [labels, setLabels] = useState([]);

  const { labels: contextLabels } = useContext(UserContext);

  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    const assignedLabels = labelsOnSelectedDocuments || contextLabels;
    setLabels(filterSearchedProperties(assignedLabels, search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextLabels, search, labelsOnSelectedDocuments]);

  const searchBoxMenuStyle = {
    width: '100%',
    minWidth: '300px',
    height: '40px',
    borderRadius: '8px',
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${theme.palette.gray.light}`,
    outline: `solid 3px ${theme.palette.gray.white}`
  };

  const menuItems = () => {
    return (
      <Scrollbar sx={{ height: '250px', minHeight: '50px', padding: '5px 0px' }}>
        {labels?.map(item => (
          <MenuItem
            disableRipple
            data-testid={`labelMenu-${item.id}`}
            key={item.id}
            onClick={() => handleLabelChange(item, selectedLabels, setSelectedLabels)}
            className={classes.menuItems}>
            <LightTooltip
              title={item.name}
              PopperProps={{
                disablePortal: labels.length > 1,
                style: { whiteSpace: 'pre-line', overflowWrap: 'break-word' }
              }}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  fontSize: theme.typography.pxToRem(16),
                  pt: '2px'
                }}
                data-testid={`menuItem-${item.id}`}>
                {item.name}
              </Typography>
            </LightTooltip>
            <Box sx={{ pointerEvents: 'none' }}>
              <CustomCheckbox
                disableRipple
                checked={isLabelMenuItemChecked(item, existingLabels, selectedLabels)}
              />
            </Box>
          </MenuItem>
        ))}
      </Scrollbar>
    );
  };

  return (
    <>
      {showBackButtonItem && (
        <MenuItem
          disableRipple
          className={classes.headerItem}
          onClick={() => setIsChangeLabelInProgress(false)}>
          <ListItemIcon>
            <Icons iconName={'grayBack'} />
          </ListItemIcon>
          <Typography variant='body2' sx={{ fontFamily: theme.typography.fontFamilyPrimaryMedium }}>
            {t('backToMenu')}
          </Typography>
        </MenuItem>
      )}
      <Box padding='5px 10px'>
        <SearchWrapper
          search={search}
          searchValue={searchValue}
          setSearch={setSearch}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          component='customOutlinedInput'
          style={searchBoxMenuStyle}
          placeholder='searchLabels'
          onKeyDownHandler={e => e.stopPropagation()}
        />
      </Box>
      {menuItems()}
      {showApplyAndClearAllButtons && (
        <Box className={classes.menuButtonsContainer}>
          <PrimaryButton
            dataTestId='applyMultiLabelButton'
            isDisabled={!selectedLabels?.length}
            handleClick={applyMultiLabelSelection}
            buttonText={t('apply')}
            width={'45%'}
          />
          <PrimaryButton
            variant='white'
            buttonText={t('clearAll')}
            width={'45%'}
            handleClick={() => {
              setSelectedLabels([]);
            }}
          />
        </Box>
      )}
    </>
  );
};

LabelMenu.propTypes = {
  existingLabels: PropTypes.array,
  selectedLabels: PropTypes.array,
  setSelectedLabels: PropTypes.func,
  setIsChangeLabelInProgress: PropTypes.func,
  showBackButtonItem: PropTypes.bool,
  showApplyAndClearAllButtons: PropTypes.bool,
  applyMultiLabelSelection: PropTypes.func,
  labelsOnSelectedDocuments: PropTypes.array
};
