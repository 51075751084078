import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Card, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { Icons, MyAvatar } from 'components';
import {
  COMMENT_PREFIX,
  changeActiveCommentsHighlighting,
  getElementsWithoutCommentClasses,
  MESSAGE_TYPES,
  removeCommentFormatOnParagraph,
  removeCommentFormatOnSelection,
  stringifyCommentValues
} from 'helpers';
import { UserContext, WebSocketConnectionContext } from 'providers';

import { Mentions } from './Mentions';

export const CommentInputCard = ({
  commentSelection,
  quillEditor,
  selectionDelta,
  commentId,
  commentInputField,
  isReply,
  queryCommentId,
  selectedCommentTag,
  clearCommentSelectionAndId,
  scrollRef
}) => {
  const theme = useTheme();

  const { user } = useContext(UserContext);
  const { connection, id } = useContext(WebSocketConnectionContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [textFieldValue, setTextFieldValue] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const replyCardStyle = {
    width: '100%',
    borderTop: 2,
    pt: '5px',
    borderColor: theme.palette.gray.lighter
  };

  const clearCommentStates = () => {
    setTextFieldValue('');
    clearCommentSelectionAndId();
  };

  // When a comment card is clicked, the close button will just remove the highlighting class.
  // When a comment is added, because the commentHighlight style attributor is used, highlighting for a paragraph or part of it is removed by Quill's format method.
  // When adding a comment is canceled, both highlighting and the comment ID are removed.
  const removeIdAndHighlighting = () => {
    if (commentSelection.length === -1 && selectedCommentTag === -1) {
      changeActiveCommentsHighlighting(false, commentId);
    } else if (commentSelection.length === 0) {
      removeCommentFormatOnParagraph(quillEditor, commentSelection);
    } else {
      removeCommentFormatOnSelection(quillEditor, commentSelection);
    }
    clearCommentStates();
  };

  const addComment = () => {
    setIsClicked(true);
    const stringifiedAttributes = stringifyCommentValues(selectionDelta.current);
    !isReply &&
      connection.invoke(MESSAGE_TYPES.BROADCAST_CHANGES, Number(id), stringifiedAttributes);
    connection
      ?.invoke(MESSAGE_TYPES.ADD_COMMENT, {
        id: isReply ? nanoid(10) : commentId,
        content: textFieldValue,
        parentCommentId: isReply ? commentId : null
      })
      .then(() => {
        removeIdAndHighlighting();
        if (queryCommentId) {
          searchParams.delete('commentId');
          setSearchParams(searchParams);
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const cancelCommenting = () => {
    removeIdAndHighlighting();
    changeActiveCommentsHighlighting(false, commentId);
    if (commentSelection.length !== -1) {
      const elements = document.getElementsByClassName(`${COMMENT_PREFIX}${commentId}`);
      getElementsWithoutCommentClasses(elements, commentId);
    }
  };

  return (
    <Card
      ref={el => scrollRef?.current && (scrollRef.current[commentId] = el)}
      sx={[
        {
          bgColor: isReply ? 'transparent' : theme.palette.gray.white,
          display: 'flex',
          alignItems: 'center',
          p: !isReply && '15px',
          mt: '15px',
          m: !isReply && '15px 5px 5px 5px',
          borderRadius: isReply ? '0' : '5px',
          boxShadow: isReply ? 'none' : `0px 0px 10px 0px ${theme.palette.gray.light}`,
          overflow: 'visible',
          zIndex: !isReply && 9999
        },
        isReply && replyCardStyle
      ]}>
      <Stack sx={{ width: '100%' }}>
        <Stack>
          <Stack direction='row' spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <MyAvatar
              sx={{
                width: 32,
                height: 32
              }}
              displayName={user.displayName}
              opacity
            />
            <Typography
              sx={{
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.primary.main,
                fontWeight: 500
              }}>
              {user.displayName}
            </Typography>
          </Stack>
          <Mentions
            textFieldValue={textFieldValue}
            setTextFieldValue={setTextFieldValue}
            commentInputField={commentInputField}
          />
          <Stack direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
            <Stack onClick={e => e.stopPropagation()}>
              <IconButton
                onClick={cancelCommenting}
                disableRipple
                sx={{
                  '&:hover': { bgColor: 'transparent' }
                }}>
                <Icons iconName={'roundExit'} />
              </IconButton>
            </Stack>
            <IconButton
              onClick={addComment}
              disabled={!textFieldValue.length || isClicked}
              disableRipple
              sx={{
                '&:hover': { bgColor: 'transparent' }
              }}>
              <Icons iconName={'send'} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

CommentInputCard.propTypes = {
  commentSelection: PropTypes.object,
  selectionDelta: PropTypes.object,
  quillEditor: PropTypes.object,
  commentId: PropTypes.string,
  commentInputField: PropTypes.object,
  isReply: PropTypes.bool,
  queryCommentId: PropTypes.string,
  selectedCommentTag: PropTypes.number,
  clearCommentSelectionAndId: PropTypes.func,
  scrollRef: PropTypes.object
};
